<template>
  <v-row>
    <v-col cols="12">
      <v-textarea v-if="$vuetify.breakpoint.mobile" dense rows="3" label="* Descricao" :rules="[
        this.$validators.string.required,
        v => this.$validators.string.lessThanOrEquals(v, 100),
        v => this.$validators.string.greaterThanOrEquals(v, 1)
      ]" v-model.trim="value.descricao" />
      <v-text-field v-else label="* Descricao" dense :rules="[
        this.$validators.string.required,
        v => this.$validators.string.lessThanOrEquals(v, 100),
        v => this.$validators.string.greaterThanOrEquals(v, 1)
      ]" v-model.trim="value.descricao" />
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field dense label="* Preço" prefix="R$" :rules="[
        this.$validators.number.required,
        v => this.$validators.number.greaterThanOrEquals(v, 0)
      ]" v-model="value.preco" type="number" />
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field dense label="* Qtd Horas" v-model="value.qtdHoras" :rules="[
        this.$validators.number.required,
        v => this.$validators.number.greaterThanOrEquals(v, 1)
      ]" type="number" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LocacaoForm",
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style scoped>
</style>